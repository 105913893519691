<template>
  <v-dialog
    v-bind="$attrs"
    v-on="$listeners"
    persistent
    max-width="900"
    transition="dialog-bottom-transition"
  >
    <v-card tile flat>
      <v-toolbar
        color="error"
        dark
      >
        <v-toolbar-title>Solicitud de cancelación</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <slot name="action"></slot>
        </v-toolbar-items>
      </v-toolbar>
      <v-card-title>
        Orden: {{ orderNumber }}
      </v-card-title>
      <v-card-subtitle>
        Cliente: {{ customerOrder }}<br>
        Total: ${{ totalOrder }}<br>
      </v-card-subtitle>
      <v-card-text>
        <kn-select
          v-model="cancellation.id_motivo_cancelacion"
          label="Motivo de cancelación"
          :rules="[]"
          :items="cancellationReasons"
          item-text="dato"
          item-value="id"
        />
        <kn-select
          v-model="cancellation.id_estatus_cancelacion"
          label="Estatus de cancelación"
          :rules="[]"
          :items="cancellationStatus"
          item-text="dato"
          item-value="id"
        />
        <kn-text-area
          v-model="cancellation.comentarios"
          label="Comentarios"
          :rules="[]"
        />
        <kn-select
          v-model="cancellation.id_aprobador"
          label="Quien aprueba?"
          :rules="[]"
          :items="adminEmployees"
          item-text="datos_fiscales.nombre_o_razon_social"
          item-value="id"
        />
        <kn-check-box
          v-model="cancellation.solicitado_por_cliente"
          label="Acepto continuar con la cancelación"
          :rules="[]"
        />
        <section v-if="cancellation.solicitado_por_cliente">
          <kn-form-subtitle title="Egreso por cancelación" />
          <kn-select
            v-model="cancellation.id_categoria"
            label="Categoría"
            :rules="[]"
            :items="expenseCategories"
            item-text="dato"
            item-value="id"
          />
          <kn-select
            v-model="cancellation.id_sub_categoria"
            label="Sub categoría"
            :rules="[]"
            :items="expenseSubcategories"
            item-text="dato"
            item-value="id"
          />
          <kn-select
            v-model="cancellation.id_forma_de_pago"
            label="Forma de pago"
            :rules="[]"
            :items="paymentMethods"
            item-text="dato"
            item-value="id"
          />
          <kn-select
            v-model="cancellation.id_cuenta_origen"
            label="Cuenta de origen"
            :rules="[]"
            :items="bankAccounts"
            item-text="nombre_cuenta"
            item-value="id"
          />
        </section>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="error"
          tile
          :disabled="!cancellation.solicitado_por_cliente"
          @click="confirm"
        >
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import KnSelect from '../inputs/KnSelect.vue'
import KnCheckBox from '../inputs/KnCheckBox.vue'
import { apiMixin } from '@/mixins/apiMixin'
import KnTextArea from '../inputs/KnTextArea.vue'
import KnFormSubtitle from '../KnFormSubtitle.vue'
export default {
  components: {
    KnSelect,
    KnCheckBox,
    KnTextArea,
    KnFormSubtitle,
  },
  mixins: [apiMixin],
  props: {
    orderId: {
      type: Number,
      default: null
    },
    orderNumber: {
      type: String,
      default: ''
    },
    customerOrder: {
      type: String,
      default: ''
    },
    totalOrder: {
      type: String,
      default: ''
    },
    cancellationReasons: {
      type: Array,
      default: () => []
    },
    cancellationStatus: {
      type: Array,
      default: () => []
    },
    adminEmployees: {
      type: Array,
      default: () => []
    },
    expenseCategories: {
      type: Array,
      default: () => []
    },
    expenseSubcategories: {
      type: Array,
      default: () => []
    },
    paymentMethods: {
      type: Array,
      default: () => []
    },
    bankAccounts: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      cancellation: {
        id_orden: null,
        id_motivo_cancelacion: null,
        id_estatus_cancelacion: null,
        comentarios: '',
        id_aprobador: null, // Empleado
        solicitado_por_cliente: false,
        id_autor: null, // Usuario logueado
        id_institucion_educativa: null
      }
    }
  },
  methods: {
    closeDialog() {
      console.log('Se debe cerrar el modal');
      this.openCancellationDialog = false
    },
    confirm() {
      this.$emit('confirm', this.cancellation)
    }
  }
}
</script>
