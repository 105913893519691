<template>
  <section>
    <kn-table 
      :headers="headers"
      :items="items"
      :loading="loading"
      :fullWidth="true"
      :disableBtnCreate="true"
      tableTitle="Ordenes"
      newButtonLabel="Agregar orden"
      @action="openCancelModal"
    />
    <kn-cancellation-modal
      v-model="openCancellationDialog"
      v-bind="orderSelected"
      :cancellationReasons="cancellationReasons"
      :cancellationStatus="cancellationStatus"
      :adminEmployees="adminEmployees"
      :expenseCategories="expenseCategories"
      :expenseSubcategories="expenseSubcategories"
      :paymentMethods="paymentMethods"
      :bankAccounts="bankAccounts"
      @confirm="confirmCancellation"
    >
      <template #action>
        <v-btn
          dark
          text
          @click="openCancellationDialog = false"
        >
          Cerrar
        </v-btn>
      </template>
    </kn-cancellation-modal>
  </section>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
import KnTable from '@/components/tables/KnTable.vue'
import KnCancellationModal from '@/components/modals/KnCancellationModal.vue'
import { apiMixin } from '@/mixins/apiMixin'
export default {
  components: { KnTable, KnCancellationModal },
  mixins: [apiMixin],
  data() {
    return {
      groups: [],
      products: [],
      headers: [
        { text: 'Fecha', value: 'fecha', class: 'purple--text' },
        { text: '# Orden', value: 'numero_orden', class: 'purple--text' },
        { text: 'Estatus', value: 'estatus_orden.dato', class: 'purple--text' },
        { text: 'Cliente', value: 'cliente', class: 'purple--text' },
        { text: 'Peso volumétrico', value: 'peso_volumetrico', class: 'purple--text' },
        { text: 'Subtotal', value: 'sub_total', class: 'purple--text' },
        { text: 'Impuestos', value: 'total_impuestos', class: 'purple--text' },
        { text: 'Total', value: 'total_orden', class: 'purple--text' },
        { text: '', value: 'acciones', class: 'purple--text' },
      ],
      items: [],
      orders: [],
      orderSelected: null,
      orderToCancel: null,
      search: null,
      loading: true,
      openCancellationDialog: false,
      orderStatus: [],
      /** Arrays para cancelacion */
      cancellationReasons: [],
      cancellationStatus: [],
      adminEmployees: [],
      /** ****** */
      /** Arrays para egreso */
      expenseCategories: [],
      expenseSubcategories: [],
      paymentMethods: [],
      coins: [],
      expenseStatus: [],
      bankAccounts: [],
      /** ****** */
    }
  },
  computed: {
    ...mapState(['institutionId', 'userData', 'warehouse'])
  },
  async created() {
    this.setIsLogin(false)
    this.loading =  true
    await this.fetchOrders(this.institutionId)
    await this.getArraysForModal()
    this.loading = false
  },
  methods: {
    ...mapMutations(['setIsLogin']),
    async fetchOrders(institutionId) {
      try {
        const orders = await this.fetchResultsByEI('ordenes', 'orden', institutionId)
        for (const order of orders) {
          const student = {...order.alumno}
          const personalInfo = await this.fetchObjectById('personas', 'datos-personales-all', student.datos_personales)
          const {
            primer_nombre: pn,
            segundo_nombre: sn,
            apellido_paterno: ap,
            apellido_materno: am
          } = personalInfo
          const fullName = `${pn} ${sn} ${ap} ${am}`
          const item = {
            fecha: order.fecha_elaboracion.substring(0, 10),
            ...order,
            cliente: fullName
          }
          this.items.push(item)
        }
      } catch (error) {
        console.error('Error al obtener ingresos.', error);
      }
    },
    openCancelModal(orderId) {
      console.log('id orden seleccionada: ', orderId)
      const orderSelected = this.items.find(order => order.id === orderId)
      console.log('Orden seleccionada: ', orderSelected);
      this.orderToCancel = {...orderSelected}
      this.orderSelected = {
        orderId: orderSelected.id,
        orderNumber: orderSelected.numero_orden,
        customerOrder: orderSelected.cliente,
        totalOrder: orderSelected.total_orden
      }
      this.openCancellationDialog = true
    },
    async getArraysForModal() {
      if (this.orderStatus.length === 0) {
        const status = await this.fetchResultsAll('ordenes', 'mv-estatus-orden-all')
        this.orderStatus = status.filter(s => s.dato
                                                .toLowerCase()
                                                .includes('cancelada'))
      }
      if (this.cancellationReasons.length === 0) {
        this.cancellationReasons = await this.fetchResultsAll('ordenes', 'motivo-cancelacion-all')
      }
      if (this.cancellationStatus.length === 0) {
        this.cancellationStatus = await this.fetchResultsAll('ordenes', 'estatus-cancelacion-all')
      }
      if (this.adminEmployees.length === 0) {
        const employees = await this.fetchResultsByEI('personas', 'empleado', this.institutionId)
        if (employees.length) {
          this.adminEmployees = employees.filter(employee => employee.tipo_empleado.dato
                                                              .toLowerCase()
                                                              .includes('administrativo'))
        }
      }
      if (this.expenseCategories.length === 0) {
        const categories = await this.fetchResultsByEI('administracion', 'categoria-egreso', this.institutionId)
        this.expenseCategories = categories.filter(category => category.dato
                                                                .toLowerCase()
                                                                .includes('cancelacion'))
      }
      if (this.expenseSubcategories.length === 0) {
        const subcategories = await this.fetchResultsByEI('administracion', 'subcategoria-egreso', this.institutionId)
        this.expenseSubcategories = subcategories.filter(subcategory => subcategory.dato
                                                                        .toLowerCase()
                                                                        .includes('devolucion'))
      }
      if (this.paymentMethods.length === 0) {
        this.paymentMethods = await this.fetchResultsByEI('administracion', 'forma-de-pago', this.institutionId)
      }
      if (this.coins.length === 0) {
        this.coins = await this.fetchResultsByEI('administracion', 'moneda', this.institutionId)
      }
      if (this.expenseStatus.length === 0) {
        this.expenseStatus = await this.fetchResultsByEI('administracion', 'estatus-egreso', this.institutionId)
      }
      if (this.bankAccounts.length === 0) {
        this.bankAccounts = await this.fetchResultsByEI('administracion', 'cuanta', this.institutionId)
      }
    },
    async confirmCancellation(cancellationObj) {
      console.log('Objeto de cancelacion', cancellationObj);
      const orderStatusId = this.orderStatus.length ? this.orderStatus[0].id : null
      const orderId = cancellationObj.id_orden
      await this.updateOrderStatus(orderId, orderStatusId)
      await this.createExpense(cancellationObj)
    },
    async createCancellation(cancellationObj) {
      try {
        const response = await this.postObj('/ordenes/crear-cancelacion', cancellationObj)
        if (response.error) {
          // this.errors.push(response.error)
          console.log('Error al crear cancelacion', response.error);
        }
      } catch (error) {
        console.error('Error al intentar crear una cancelación', error);
      }
    },
    async updateOrderStatus(orderId, orderStatusId) {
      try {
        const response = await this.postObj('/ordenes/update-estatus-orden', {
          id_orden: orderId,
          id_status_orden: orderStatusId
        })
        if (response.error) {
          console.log('Error al actualizar estatus de orden', response.error)
        }
      } catch (error) {
        console.error('Error al intentar actualizar estatus de orden', error);
      }
    },
    async createExpense({
      id_categoria,
      id_sub_categoria,
      id_forma_de_pago,
      id_cuenta_origen
    }) {
      try {
        const approvedStatus = this.expenseStatus.find(eStatus => eStatus.dato.toLowerCase().includes('aprobado'))
        let  expense = {
          id_proveedor: null,
          id_empleado: null,
          id_categoria: id_categoria,
          id_sub_categoria: id_sub_categoria,
          sub_total: this.orderToCancel.sub_total,
          total_impuestos: this.orderToCancel.total_impuestos,
          costo_envio: this.orderToCancel.costo_envio,
          total_descuento: this.orderToCancel.total_descuento,
          total_egreso: this.orderToCancel.total_orden,
          descuento_aplicado: this.orderToCancel.descuento_aplicado,
          id_forma_de_pago: id_forma_de_pago,
          id_moneda: this.orderToCancel.moneda.id,
          id_cuenta_origen: id_cuenta_origen,
          id_estatus: approvedStatus.id,
          id_centro_de_costos: 1,
          id_autor: this.userData.id,
          id_institucion_educativa: this.institutionId
        }
        console.log('Objeto expense', expense);
        const response = await this.postObj('/administracion/crear-egreso', expense)
        console.log('Respuesta crear egreso', response);
      } catch (error) {
        console.error('Error al intentar crear egreso', error);
      }
    },
    async updateInventory(item) {
      try {
        const inventory = {...item.inventory}

        const response = await this.postObj('/inventarios/update-inventario', {
          id: inventory.id,
          id_producto: item.productId,
          id_opcion_producto: inventory.opcion_producto.id,
          id_almacen: inventory.almacen.id,
          cantidad_disponible: inventory.cantidad_disponible - item.quantity,
          id_unidad_de_medida: inventory.unidad_medida.id,
          comentarios: inventory.comentarios,
          fecha_ultima_actualizacion: new Date(),
          id_autor: this.userData.id,
          id_institucion_educativa: this.institutionId
        })
        if (response) {
          this.alertText = 'Inventario actualizado con exito'
        } else {
          this.errors.push('No se pudo actualizar inventario')
        }
      } catch (error) {
        console.error('Error al intentar crear inventario');
      }
    },
    async createInventoryMovement(item) {
      try {
        this.movementTypes = await this.fetchResultsAll('inventarios', 'mv-movimiento-inventario-all')
        this.movementReasons = await this.fetchResultsAll('inventarios', 'motivo-movimiento-inventario-all')
        const movementType = this.movementTypes.find(mType => mType.dato.toLowerCase().includes('entrada'))
        const movementReason = this.movementReasons.find(mReason => mReason.dato.toLowerCase().includes('devolucion'))

        const inventory = {...item.inventory}

        const initialAmount = parseFloat(inventory.cantidad_disponible)
        const amountOfMovement = item.quantity
        const finalAmount = initialAmount - amountOfMovement
        const unitPrice = parseFloat(item.price)
        const valueOfMovement = unitPrice * amountOfMovement

        const movementObj = {
          id_producto: item.productId,
          id_tipo_movimiento: movementType.id, // Tipo de movimiento: Salida
          id_motivo_movimiento: movementReason.id, // Motivo de movimiento: Venta
          id_almacen: inventory.almacen.id,
          inventario_inicial: initialAmount,
          cantidad_movimiento: amountOfMovement,
          inventario_final: finalAmount,
          precio_unitario: unitPrice,
          valor_movimiento: valueOfMovement,
          id_moneda: this.mxCoin.id,
          id_autor: this.userData.id,
          id_institucion_educativa: this.institutionId
        }

        const response = await this.postObj('/inventarios/crear-movimiento-inventario', movementObj)

        if (response) {
          this.alertText = 'Movimiento de inventario creado con exito'
        } else {
          this.errors.push('No se pudo crear movimiento de inventario.')
        }
      } catch (error) {
        console.error('Error al intentar crear movimiento de inventario', error)
        this.errors.push('Error al intentar crear movimiento de inventario')
      }
    }
  }
}
</script>
